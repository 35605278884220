<template>
  <b-container class="mt-4">
    <b-card header-tag="header" header-class="header">
      <template #header>
        <label v-text="'Raporlar'" class="mr-2 text-white"></label>
      </template>
      <b-list-group>
        <b-list-group-item v-for="(item, index) in titles" :key="index">
          <b-badge variant="secondary">{{ index + 1 }}</b-badge>
          <router-link
            :to="item.route"
            style="margin-left: 1rem; color: black"
            >{{ item.title }}</router-link
          >
        </b-list-group-item>
      </b-list-group>
    </b-card>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      titles: [
        { title: "Antrenör", route: "/trainerReport" },
        { title: "Sporcu", route: "/sportReport" },
        { title: "Hakem", route: "/refereeReport" },
        { title: "Kulüp", route: "/clubReportSelect" },
        { title: "Gelişmiş Raporlama", route: "/advancedReports" },
      ],
    };
  },
};
</script>

<style scoped>
.header {
  background: rgb(108, 117, 125);
  background: linear-gradient(
    180deg,
    rgb(108, 117, 125) 10%,
    rgba(53, 57, 61, 1) 85%
  );
}
</style>